import { Box, Grid, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { Button, SearchField } from '../../../atoms';
import { FilterTab } from '../filterTab';
import { userManagementTopContainer_style } from './style';

function UserManagementTopContainer(props) {
  const {
    className = '',
    handleAddClick = () => {},
    handleSearch = () => {},
    // title = '',
    count = 0,
    searchFieldPlaceholder = '',
    showSearchField = true,
    addButtonName = '',
    showAddButton = true,
    addMasterStyle = {},
    rootStyle = {},
    currentTab,
    searchValue = '',
    ...rest
  } = props;

  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    currentTab(newValue);
  };

  const handleSearchFnc = (newData) => {
    handleSearch(newData);
  };
  // const filterList = ['Admin', 'Doctor', 'Expert'];
  const filterList = [
    { id: 'admin', name: 'Admin', count: tabIndex === 0 ? count : -1 },
    { id: 'doctor', name: 'Doctor', count: tabIndex === 1 ? count : -1 },
    { id: 'expert', name: 'Expert', count: tabIndex === 2 ? count : -1 },
  ];

  return (
    <Box
      sx={{
        ...userManagementTopContainer_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                backgroundColor: '#EDEFF2',
                borderRadius: '10px',
              }}
            >
              <FilterTab
                // id={filterList?.data_testId}
                filterList={filterList}
                handleChange={handleChange}
                // filterTabRootStyle={
                //   userManagementTopContainer_style.filterTabSx
                // }
                filterTabRootStyle={{
                  width: '150px',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#3D3D3D',
                }}
                value={tabIndex}
                count={count > 0 && count < 10 ? `0${count}` : count}
                showCount
              />
            </Box>

            {/* <Typography sx={userManagementTopContainer_style.masterTextSx}>
              {`${title} (${count > 0 && count < 10 ? `0${count}` : count})`}
            </Typography> */}
          </Box>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            {showSearchField && (
              <Grid item>
                <SearchField
                  id="search"
                  searchFieldStyle={
                    userManagementTopContainer_style.searchFieldSx
                  }
                  placeholder={searchFieldPlaceholder ?? ''}
                  onSearch={searchValue}
                  setOnSearch={handleSearchFnc}
                  startAdornment={
                    <IconButton disableRipple sx={{ mt: 0.5, mr: -2.5 }}>
                      <BiSearch />
                    </IconButton>
                  }
                />
              </Grid>
            )}
            {showAddButton && (
              <Grid item>
                <Button
                  onClick={() => handleAddClick()}
                  buttonStyle={{
                    ...userManagementTopContainer_style.addMasterSx,
                    ...addMasterStyle,
                  }}
                >
                  {addButtonName}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

UserManagementTopContainer.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  handleAddClick: PropTypes.func,
  handleSearch: PropTypes.func,
  title: PropTypes.string,
  count: PropTypes.number,
  searchFieldPlaceholder: PropTypes.string,
  showSearchField: PropTypes.bool,
  addButtonName: PropTypes.string,
  showAddButton: PropTypes.bool,
};

export { UserManagementTopContainer };

export const clinicalRoutes = {
  // Home
  landingPage: '/',
  awarenesspart: '/awareness',
  home: '/home',
  // onboarding
  signup: '/createaccount',
  onboarding: '/onboarding',
  login: '/login',
  forgotPassword: '/forgotpassword',
  resetPassword: '/resetpassword',
  otp: '/otpverification',
  splash: '/splash',
  otpSplash: '/otpSplash',
  // profile
  profileView: '/profile',
  addProfile: '/profile/add',
  editProfile: '/profile/edit',
  bugreports: '/bugreport',
  feedback: '/feedback',
  seekHelp: '/seekHelp',
  medicalConditions: '/medicalConditions',
  viewMedicalConditions: '/viewMedicalConditions',
  vitalMonitoring: '/vitalmonitoring',
  vitalHistory: '/vitalhistory',
  // clinical - Doctor chat
  chat: '/chat',
  chatListPage: '/chatlist',
  chatStatus: '/chatstatus',
  // family members
  myFamily: '/familymembers',
  addFamilyMember: '/familymembers/add',
  editFamilyMember: '/familymembers/edit',
  // addexistingmember
  addexistingmember: '/addexistingmember',
  // reports
  myReports: '/reportslegacy',
  reports: '/reports',
  reportsUpload: '/reportsupload',
  // circle
  // awareness: '/awareness',
  viewAwareness: '/viewAwareness',
  awarenessCategory: '/awarenessCategory',
  benefitsSlider: 'awarenesscategory/benefitsSlider',
  awarenesSlider: '/awarenesSlider',
  // appointment booking
  selectPatient: '/appointmentbooking/selectpatient',
  healthIssues: '/appointmentbooking/healthissues',
  consultationReason: '/appointmentbooking/consultationreason',
  symptoms: '/appointmentbooking/symptoms',
  vitalsForm: '/appointmentbooking/vitalsform',
  doctorAvailable: '/appointmentbooking/availabledoctors',
  healthProblems: '/appointmentbooking/healthproblems',
  allergies: '/appointmentbooking/allergies',
  medications: '/appointmentbooking/medications',
  uploadReports: '/appointmentbooking/uploadreports',
  appointmentSummary: '/appointmentbooking/appointmentsummary',
  bookingStatus: '/appointmentbooking/bookingstatus',
  selectFamilyMember: '/appointmentbooking/selectFamilyMember',
  patientDetails: '/appointmentbooking/patientDetails',
  // appointments
  myAppointments: '/appointments',

  // prescription
  prescription: '/prescription/:id',

  // Health query

  chatBot: '/chatbot',

  // mind body food
  mindbodyfood: '/mindbodyfood',
  questionevaluation: '/mindbodyfood/questionevaluation',
  questiontypeevaluation: '/mindbodyfood/questiontypeevaluation',
  questionevaluationsubmit: '/mindbodyfood/questionevaluationsubmit',
  getStarted: '/mindbodyfood/getstarted',
  mbfSplash: '/mindbodyfood/mbfSplash',
  moodforyou: '/mindbodyfood/moodforyou',
  wellnessjourneylegacy: '/mindbodyfood/wellnessjourneylegacy',
  wellnessjourney: '/mindbodyfood/wellnessjourney',
  moodstats: '/mindbodyfood/moodStats',
  mbfMoodSplash: '/mindbodyfood/mbfMoodSplash',
  mbfawards: '/mindbodyfood/awards',
  mywellnessplan: '/mindbodyfood/mywellnessplan',
  plansummary: '/mindbodyfood/plansummary',
  performance: '/mindbodyfood/performancestats',
  leaderboard: '/mindbodyfood/leaderboard',
  mbffeedback: '/mindbodyfood/feedback',
  PlanEvaluation: '/mindbodyfood/PlanEvaluation',
  // notification
  notification: '/notification',
  expertNotes: '/mindbodyfood/expertNotes',

  // public pages *
  doctorProfile: '/doctorProfile',
};
